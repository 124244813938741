import {
  createRoutesFromChildren,
  isRouteErrorResponse,
  Links,
  matchRoutes,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useNavigationType,
} from "react-router";

import type { Route } from "./+types/root";
import stylesheet from "./styles/out.css?url";
import Navbar from "./components/navbar";
import * as Sentry from "@sentry/react";
import React from "react";
import '~console/theme-detect'

Sentry.init({
  dsn: "https://bf717a336f0c131feea88c13807651d1@o4508324428513280.ingest.us.sentry.io/4508402812846080",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/itzdabbzz\.me\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


export const links: Route.LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
  { rel: "stylesheet", href: stylesheet },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Dabz's Site</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Navbar />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default Sentry.withProfiler(App);

export function ErrorBoundary({ error }: Route.ErrorBoundaryProps) {
  let message = "Oops!";
  let details = "An unexpected error occurred.";
  let stack: string | undefined;

  if (isRouteErrorResponse(error)) {
    message = error.status === 404 ? "404" : "Error";
    details =
      error.status === 404
        ? "The requested page could not be found."
        : error.statusText || details;
  } else if (import.meta.env.DEV && error && error instanceof Error) {
    details = error.message;
    stack = error.stack;
  }

  return (
    <main className="min-h-screen bg-gray-950 text-white flex items-center justify-center p-4">
      <div className="max-w-2xl w-full bg-gray-900/95 backdrop-blur-sm rounded-xl shadow-lg shadow-purple-500/10 ring-1 ring-gray-700/50 border border-gray-800 p-8">
        <h1 className="text-4xl font-bold text-purple-400 mb-4">{message}</h1>
        <p className="text-gray-300 mb-6">{details}</p>
        {stack && (
          <pre className="bg-gray-950 p-4 rounded-lg overflow-x-auto border border-gray-800 text-gray-400 text-sm">
            <code>{stack}</code>
          </pre>
        )}
      </div>
    </main>
  );
}
